<template>
  <div style="padding-left: 1.75rem;">
    <div class="col2_head">
      <span><router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link> {{ $route.name }}</span>
      <div class="subHeadTitle">{{ $route.name }}</div>
    </div>
    <h3 style="display: flex; flex-direction: column; align-items: center;">
      <span style="font-size: 4rem;">
        <i class="far fa-clock"></i>
      </span>
      In Curand
    </h3>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>